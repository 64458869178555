import React from 'react';

const AmpersandIcon = () => (
  <svg
    height="15px"
    width="30px"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 32 32"
    xmlSpace="preserve"
	
  >
    <g>
      <g id="ampersand">
        <path
          style={{ fill: 'white' }}
          d="M16.101,32c-2.58,0-4.939-0.898-6.74-2.695c-3.59-3.594-3.59-9.445,0-13.035l8.336-7.938
            c0.461-0.465,0.727-1.102,0.727-1.785c0-0.68-0.266-1.32-0.742-1.805c-0.993-0.992-2.833-0.988-3.833,0.004
            c-0.985,0.992-0.985,2.609,0.007,3.602l-2.832,2.832c-2.55-2.555-2.55-6.707-0.003-9.262c2.562-2.559,6.934-2.555,9.49-0.004
            c1.242,1.238,1.922,2.887,1.922,4.637s-0.68,3.395-1.922,4.629l-8.338,7.934c-2.02,2.016-2.02,5.328,0.02,7.363
            c2.031,2.035,5.566,2.031,7.607,0c2.031-2.035,2.031-5.348,0-7.379l-1.424-1.414l4.354-4.348l2.602,2.828l-1.672,1.672
            c2.266,3.562,1.852,8.359-1.031,11.469C20.831,31.102,18.462,32,16.101,32L16.101,32z"
        />
      </g>
    </g>
  </svg>
);

export default AmpersandIcon;
