const isMobile = /Mobi/.test(window.navigator.userAgent);

const apiUrl = isMobile ?   process.env.REACT_APP_MOBILE_API_URL: process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;
const mainUrl = process.env.REACT_APP_MAIN_URL;
const  firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};
export {
  apiUrl,
 apiKey,
  
  mainUrl,
  firebaseConfig
};
