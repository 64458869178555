import React from 'react';
import './index.css';

import { Outlet } from 'react-router';
// import{Tutorial} from './models';

function App() {
   
   return <>
   <Outlet />
   </>
}

export default App;



